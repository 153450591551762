import apiService from "@/api/V2/services/api.service";

const getAllReports = (filters) => {
  return apiService.get(`/v2/codReport/getAllReports`, { params: filters });
}

const downloadCodReport = (id) => {
  return apiService.postBlob(`/v2/documents/downloadCodReport/`,{
    id: id,
  });
};

const downloadPaymentOrder = (id) => {
  return apiService.postBlob(`/v2/documents/downloadPaymentOrder/`,{
    report_id: id,
  });
};

const uploadReportPaymentOrderFile = (data) => {
  return apiService.post(`/v2/codReport/uploadPaymentOrderFile/`, data);
};

const editReport = (id, paymentOrderNumber, paymentSum, paymentOrderDate) => {
  return apiService.post(`/v2/codReport/editReport`,{
    cod_report_id: id,
    paymentOrderNumber: paymentOrderNumber,
    payment_sum: paymentSum,
    paymentOrderDate: paymentOrderDate
  });
};
export {
  getAllReports,
  downloadCodReport,
  downloadPaymentOrder,
  uploadReportPaymentOrderFile,
  editReport,
}
