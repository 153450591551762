<template>
  <el-row style="margin-top: 20px">
    <el-form-item>
      <el-date-picker
        style="display: inline-block; vertical-align: middle; height: 41px;"
        class="input--date-range"
        v-model="dateRange"
        type="daterange"
        start-placeholder="Дата от"
        end-placeholder="Дата до"
        @change="mapMultipleValues"
      />
    </el-form-item>
    <el-form-item>
      <ShopSelect style="margin-left: 30px" :type-shops="1" @change="onChangeShopId($event)"></ShopSelect>
    </el-form-item>
    <el-form-item style="margin-left: 50px">
      <el-button type="primary" @click="getAllCodReports">
        Отправить
      </el-button>
    </el-form-item>
  </el-row>
  <div class="mt-4" style="width: 1230px">
    <Grid
      ref="grid"
      :sortable="true"
      :sort="sort"
      :data-items="paginatedProducts"
      :columns="getColumns"
      :pageable="true"
      :page-size="pageSize"
      :total="total"
      :skip="skip"
      :take="take"
      @pagechange="pageChangeHandler"
    >
      <template v-slot:download="{ props }">
        <td>
          <KButton
            theme-color="primary"
            @click="downloadReport(props.dataItem.id)"
          >
            Скачать
          </KButton>
        </td>
      </template>

      <template v-slot:edit="{ props }">
        <td>
          <KButton
            theme-color="warning"
            @click="openEditDialog(props.dataItem)"
          >
            Редактировать
          </KButton>
        </td>
      </template>
    </Grid>

    <el-dialog
      title="Редактирование отчёта"
      v-model="windowVisible"
      width="700px"
      :style="{ minHeight: '300px' }"
      align-center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      custom-class="custom-dialog"
    >
      <el-form :model="editReportForm" label-position="top" ref="editFormRef">
        <el-form-item label="Номер платежного поручения" prop="paymentOrderNumber">
          <el-input
            :minlength="order.number.minLength"
            :maxlength="order.number.maxLength"
            v-model="editReportForm.paymentOrderNumber"
            placeholder="Введите номер платежного поручения"
            :disabled="editDisabled"
            required
          />
        </el-form-item>

        <el-form-item label="Сумма платежного поручения" prop="paymentSum">
          <el-input
            :minlength="order.number.minLength"
            :maxlength="order.number.maxLength"
            v-model="editReportForm.paymentSum"
            placeholder="Введите сумму платежного поручения"
            :disabled="editDisabled"
            required
          />
        </el-form-item>

        <el-form-item label="Дата платежного поручения" prop="paymentDate">
          <DatePicker
            style="width: 100%;"
            v-model="editReportForm.paymentDate"
            placeholder="Выберите дату платежного поручения"
            :disabled="editDisabled"
            required
          />
        </el-form-item>
      </el-form>

      <el-form-item label="Платежное поручение" prop="paymentFile">
        <div class="upload-tip">
          Поддерживаются файлы: pdf, doc, docx, xlsx, xls, ppt, pptx
        </div>
        <div class="button-container">
          <div class="upload-buttons">
            <el-tooltip
              class="box-item"
              effect="dark"
              :disabled="currentReport.payment !== null"
              content="Заполните и сохраните остальные поля"
              placement="top-start"
            >
              <el-upload
                class="upload-demo"
                :disabled="editDisabled"
                :auto-upload="true"
                :http-request="handleUpload"
                :before-upload="beforeUpload"
                accept=".pdf,.doc,.docx,.xlsx,.xls,.ppt,.pptx"
                :show-file-list="false"
              >
                <el-button
                  size="small"
                  type="primary"
                  :disabled="!currentReport.payment"
                  class="upload-button"
                >Загрузить файл
                </el-button>
              </el-upload>
            </el-tooltip>
            <div style="padding-left: 30px;">
              <el-tooltip
                class="box-item"
                effect="dark"
                :disabled="currentReport.payment !== null && currentReport.payment.payment_order_path !== null  "
                content="Файл не найден"
                placement="top-start"
              >
                <el-button
                  size="small"
                  type="success"
                  @click="downloadPaymentOrder(currentReport.id)"
                  class="download-button"
                  :disabled="!currentReport.payment || !currentReport.payment.payment_order_path"
                >Скачать файл
                </el-button>
              </el-tooltip>
            </div>
          </div>

        </div>
      </el-form-item>


      <template #footer>
        <div class="dialog-footer">
          <el-button @click="closeEditDialog" type="info">Отмена</el-button>
          <el-button type="primary" @click="submitEditForm" :disabled="editDisabled">
            Подтвердить
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>


<script>
import { Grid } from '@progress/kendo-vue-grid';
import '@progress/kendo-theme-default/dist/all.css';
import { Button } from '@progress/kendo-vue-buttons';
import {
  downloadCodReport,
  editReport,
  getAllReports,
  uploadReportPaymentOrderFile,
  downloadPaymentOrder
} from "./api/api";
import { ElNotification } from "element-plus";
import DatePicker from "../../components/toolbar/DatePicker.vue";
import { downloadFile, getFileNameWithExtensionFromHeaders } from "../../store/helper";
import ShopSelect from "../../components/selectors/shopSelect/ShopSelect.vue";

export default {
  name: "CodReports",
  components: {
    ShopSelect,
    Grid,
    KButton: Button,
    DatePicker,
  },
  created() {
    this.getAllCodReports();
  },
  data() {
    return {
      pageSize: 10,
      sort: null,
      skip: 0,
      take: 10,
      total: 0,
      products: [],
      dateRange: null,
      successAudio: new Audio(require('@/assets/audio/positive.mp3')),
      errorAudio: new Audio(require('@/assets/audio/negative.mp3')),
      windowVisible: false,
      currentReport: null,
      filters: {
        date_from: null,
        date_to: null,
        shopId: null,
      },
      editReportForm: {
        paymentOrderNumber: '',
        paymentSum: '',
        paymentDate: null,
      },
      order: {
        number: {
          minLength: 4,
          maxLength: 40
        },
      },
      editDisabled: false,
      fileList: [],
      filePreviewVisible: false,
      previewFileUrl: '',
    };
  },
  computed: {
    paginatedProducts() {
      return this.products.slice(this.skip, this.skip + this.take);
    },
    getColumns() {
      return [
        {
          field: 'id',
          title: 'ID',
          width: '50px',
        },
        {
          field: 'shop_name',
          title: 'Название ИМ',
          width: '200px',
        },
        {
          field: 'report_date',
          title: 'Дата отчета',
          width: '200px',
        },
        {
          field: 'shipments_count',
          title: 'Кол-во отправлений',
          width: '200px',
        },
        {
          field: 'sbp_sum_display',
          title: 'Сумма по СПБ',
          width: '150px',
        },
        {
          field: 'card_sum_display',
          title: 'Сумма по Банк. карте',
          width: '180px',
        },
        {
          field: 'cash_sum_display',
          title: 'Сумма наличными',
          width: '180px',
        },
        {
          field: 'report_sum_display',
          title: 'Итоговая сумма',
          width: '150px',
        },
        {
          field: 'payment_order_number',
          title: '№ платеж. поручения',
          width: '200px',
        },
        {
          field: 'payment_order_date',
          title: 'Дата платеж. поручения',
          width: '200px',
        },
        {
          field: 'status',
          title: 'Статус отчета',
          width: '150px',
        },
        {
          field: 'downloadAction',
          title: 'Скачать',
          width: '100px',
          cell: 'download',
        },
        {
          field: 'editAction',
          title: 'Редактировать',
          width: '150px',
          cell: 'edit',
        },
      ];
    },
  },
  methods: {
    async getAllCodReports() {
      try {
        const response = await getAllReports(this.filters);
        if (response.success) {
          this.products = response.data.map(report => ({
            ...report,
            report_date: `${this.formatDate(report.date_from)} - ${this.formatDate(report.date_to)}`,
            shop_name: report.shop ? report.shop.name : 'Неизвестный магазин',
            sbp_sum_display: `${report.sbp_sum} ₽`,
            card_sum_display: `${report.card_sum} ₽`,
            cash_sum_display: `${report.cash_sum} ₽`,
            report_sum_display: `${report.report_sum} ₽`,
            payment_order_number: report.payment ? report.payment.payment_number : '-',
            payment_order_date: report.payment ? report.payment.payment_date : '-',
            payment_order_path: report.payment ? report.payment.payment_order_path : null
          }));
          this.total = this.products.length;
        } else {
          console.error("Не удалось получить отчеты:", response);
          ElNotification({
            title: 'Ошибка',
            message: 'Не удалось получить отчеты.',
            type: 'error',
          });
        }
      } catch (error) {
        console.error("Ошибка при получении отчетов:", error);
        ElNotification({
          title: 'Ошибка',
          message: 'Произошла ошибка при получении отчетов.',
          type: 'error',
        });
      }
    },
    formatDate(date) {
      if (!date) return '';
      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },
    pageChangeHandler(event) {
      console.log("Page change event:", event);
      this.take = event.page.take;
      this.skip = event.page.skip;
    },
    openEditDialog(report) {
      this.currentReport = report;
      this.editReportForm.paymentOrderNumber = report.payment ? report.payment.payment_number : '';
      this.editReportForm.paymentSum = report.payment ? report.payment.payment_sum : '';
      this.editReportForm.paymentDate = report.payment ? this.parseDate(report.payment.payment_date) : null;

      if (report.payment && report.payment.payment_order_path) {
        this.fileList = [
          {
            name: this.getFileName(report.payment.payment_order_path),
            url: report.payment.payment_order_path,
            status: 'finished',
            uid: 'existing-file',
          }
        ];
      } else {
        this.fileList = [];
      }

      this.windowVisible = true;
    },
    closeEditDialog() {
      this.windowVisible = false;
      this.resetEditForm();
    },
    resetEditForm() {
      this.editReportForm = {
        paymentOrderNumber: '',
        paymentSum: '',
        paymentDate: null,
      };
      this.editDisabled = false;
      this.fileList = [];
      this.previewFileUrl = '';
      this.filePreviewVisible = false;
    },
    mapMultipleValues() {
      if (this.dateRange !== null && this.dateRange.length) {
        this.filters.date_from = this.dateRange[0].toLocaleDateString('fr-CA');
        this.filters.date_to = this.dateRange[1].toLocaleDateString('fr-CA');
      } else {
        this.filters.date_from = '';
        this.filters.date_to = '';
      }
    },
    onChangeShopId(event) {
      this.filters.shopId = event.length > 0 ? event[0] : null;
    },
    async submitEditForm() {
      await this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) {
          ElNotification({
            title: 'Ошибка',
            message: 'Пожалуйста, исправьте ошибки в форме.',
            type: 'error',
          });
          return;
        }

        if (
          !this.editReportForm.paymentOrderNumber ||
          this.editReportForm.paymentOrderNumber.length < this.order.number.minLength ||
          this.editReportForm.paymentOrderNumber.length > this.order.number.maxLength
        ) {
          ElNotification({
            title: 'Ошибка',
            message: 'Введите корректный номер платежного поручения',
            type: 'error',
          });
          return;
        }

        if (!this.validatePaymentSum())
        {
          return;
        }

        if (!this.editReportForm.paymentDate) {
          ElNotification({
            title: 'Ошибка',
            message: 'Выберите дату платежного поручения',
            type: 'error',
          });
          return;
        }

        const payload = {
          payment_number: this.editReportForm.paymentOrderNumber,
          payment_sum: this.editReportForm.paymentSum,
          payment_date: this.formatDateForAPI(this.editReportForm.paymentDate),
        };

        try {
          const response = await editReport(this.currentReport.id, payload.payment_number, payload.payment_sum, payload.payment_date);
          if (response.success) {
            ElNotification({
              title: 'Успешно',
              message: 'Отчет обновлен.',
              type: 'success',
            });
            this.getAllCodReports();
            this.closeEditDialog();
          } else {
            throw new Error(response.message || 'Не удалось обновить отчет.');
          }
        } catch (error) {
          console.error("Ошибка при обновлении отчета:", error);
          ElNotification({
            title: 'Ошибка',
            message: error.message || 'Не удалось обновить отчет.',
            type: 'error',
          });
        }
      });
    },
    downloadReport(id) {
      downloadCodReport(id).then(response => {
        if (response.data) {
          const fileNameWithExtension = getFileNameWithExtensionFromHeaders(response.headers);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          downloadFile(url, fileNameWithExtension);
          this.successAudio.play();
        } else {
          this.errorAudio.play();

          ElNotification({
            title: 'Ошибка',
            message: 'Ошибка при скачивании отчёта!',
            type: 'error',
          });
        }
      }).catch(error => {
        console.error("Ошибка при скачивании отчета:", error);
        this.errorAudio.play();

        ElNotification({
          title: 'Ошибка',
          message: 'Произошла ошибка при скачивании отчёта.',
          type: 'error',
        });
      });
    },
    downloadPaymentOrder(id) {
      downloadPaymentOrder(id).then(response => {
        console.log(response.data)
        if (response.data) {
          const fileNameWithExtension = getFileNameWithExtensionFromHeaders(response.headers);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          downloadFile(url, fileNameWithExtension);
          this.successAudio.play();
        } else {
          this.errorAudio.play();

          ElNotification({
            title: 'Ошибка',
            message: 'Ошибка при скачивании файла платежного поручения!',
            type: 'error',
          });
        }
      }).catch(error => {
        console.error("Ошибка при скачивании файла платежного поручения:", error);
        this.errorAudio.play();

        ElNotification({
          title: 'Ошибка',
          message: 'Произошла ошибка при скачивании файла платежного поручения.',
          type: 'error',
        });
      });
    },
    validatePaymentSum() {
      const paymentSum = this.editReportForm.paymentSum;

      // 1. Проверка на пустое значение
      if (!paymentSum || paymentSum.trim().length === 0) {
        ElNotification({
          title: 'Ошибка',
          message: 'Введите сумму платежного поручения',
          type: 'error',
        });
        return false;
      }

      // 2. Проверка на корректное числовое значение
      // Используем регулярное выражение для проверки формата числа с максимум двумя десятичными знаками
      const numberPattern = /^\d+(\.\d{1,2})?$/;

      if (!numberPattern.test(paymentSum)) {
        ElNotification({
          title: 'Ошибка',
          message: 'Сумма должна быть числом с максимум двумя десятичными знаками',
          type: 'error',
        });
        return false;
      }

      const numericSum = parseFloat(paymentSum);

      if (isNaN(numericSum)) {
        ElNotification({
          title: 'Ошибка',
          message: 'Сумма должна быть допустимым числом',
          type: 'error',
        });
        return false;
      }

      if (numericSum < 0) {
        ElNotification({
          title: 'Ошибка',
          message: 'Сумма не может быть отрицательной',
          type: 'error',
        });
        return false;
      }

      return true;
    },
    parseDate(dateString) {
      const parts = dateString.split('.');
      return new Date(parts[2], parts[1] - 1, parts[0]);
    },
    formatDateForAPI(date) {
      if (!date) return '';
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    },
    beforeUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        ElNotification({
          title: 'Ошибка',
          message: 'Файл должен быть меньше 20MB!',
          type: 'error',
        });
      }
      return isLt20M;
    },
    async handleUpload({ file, onSuccess, onError }) {
      let formData = new FormData();
      formData.append('attach', file);
      formData.append('report_id', this.currentReport.id);

      try {
        const response = await uploadReportPaymentOrderFile(formData);
        if (response.success) {
          ElNotification({
            title: 'Успешно',
            message: 'Файл загружен.',
            type: 'success',
          });
          this.getAllCodReports();
          onSuccess(response, file);
          this.closeEditDialog()
        } else {
          throw new Error('Не удалось загрузить файл.');
        }
      } catch (error) {
        console.error("Ошибка при загрузке файла:", error);
        ElNotification({
          title: 'Ошибка',
          message: 'Не удалось загрузить файл.',
          type: 'error',
        });
        onError(error);
      }
    },
    getFileName(url) {
      return url.substring(url.lastIndexOf('/') + 1);
    },
  },
};
</script>


<style scoped>
::v-deep(.el-picker__popper) {
  padding: 2px 16px;
}

::v-deep(.k-window ) {
  z-index: 1000;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.upload-button {
  display: flex;
  //align-items: center;
  //justify-content: center;
  width: 150px;
  height: 36px;
}


.upload-buttons {
  display: flex;
  //align-items: start;
  //justify-content: start;
}


.upload-tip {
  margin-bottom: 10px;
  font-size: 12px;
  color: #909399;
}


.download-button {
  display: flex;
  //align-items: start;
  //justify-content: start;
  padding-left: 10px;
  width: 150px;
  height: 36px;
  padding-bottom: 10px;
}

.button-container {
  display: flex;
  justify-content: space-between;
}
</style>
