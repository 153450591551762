const permissions = {
  // Просмотр данных всех клиентов
  ANY_CLIENT_SEARCH: 'any-client-search',

  // Создание перевозки
  ANY_FREIGHT_CREATE: 'any-freight-create',

  // Редактирование любой перевозки
  ANY_FREIGHT_EDIT: 'any-freight-edit',

  // Просмотр всех перевозок
  ANY_FREIGHT_VIEW: 'any-freight-view',

  // Управление всеми партнерами
  ANY_PARTNER_MANAGE: 'any-partner-manage',

  // Управление ПВЗ
  ANY_POINT_EDIT: 'any-point-edit',

  // Управление ПВЗ
  ANY_POINT_MANAGE: 'any-point-manage',

  // Редактирование отправлений
  ANY_SHIPMENT_EDIT: 'any-shipment-edit',

  // Поиск любых отправлений
  ANY_SHIPMENT_SEARCH: 'any-shipment-search',

  // Управление магазинами
  ANY_SHOP_MANAGE: 'any-shop-manage',

  // Управление пользователями
  ANY_USER_MANAGE: 'any-user-manage',

  // Управление заявками на регистрацию
  ANY_USER_REQUEST_MANAGE: 'any-user-request-manage',

  // Управление ячейками
  CEIL_MANAGE: 'ceil-manage',

  // Управление городами
  CITY_MANAGE: 'city-manage',

  // Просмотр полной информации о клиенте
  CLIENT_FULL_INFO_MANAGE: 'client-full-info-view',

  // Управление настройками системы
  CONFIG_MANAGE: 'config-manage',

  // Создание заявки на регистрацию
  CREATE_REQUEST_REGISTRATION: 'create_request_registration',

  // Удаление отчета франчайзи
  FRANCH_REPORT_DELETE: 'franch-report-delete',

  // Управление отчетами франчайзи
  FRANCH_REPORT_MANAGE: 'franch-report-manage',

  // Управление тарифами франчайзи
  FRANCH_TARIFF_MANAGE: 'franch-tariff-manage',

  // Управление отчетами ИМ
  IM_REPORT_MANAGE: 'im-report-manage',

  // Создание уведомлений
  NEW_MANAGE: 'new-manage',

  // Просмотр актов приема-передачи
  OWN_ACT_VIEW: 'own-act-view',

  // Прием своих перевозок
  OWN_FREIGHT_ACCEPT: 'own-freight-accept',

  // Просмотр своих перевозок
  OWN_FREIGHT_VIEW: 'own-freight-view',

  // Доступ к разделу helpdesk
  OWN_HELPDESK: 'own-helpdesk',

  // Просмотр своих отчетов по остаткам
  OWN_REPORT_SURPLUS: 'own-report-surplus',

  // Просмотр своих отчетов по утилизации
  OWN_REPORT_UTIL: 'own-report-util',

  // Сбор отправки на склад
  OWN_SENDING_PREPARE: 'own-sending-prepare',

  // Просмотр отправки на склад
  OWN_SENDING_VIEW: 'own-sending-view',

  // Прием своих отправлений
  OWN_SHIPMENT_ACCEPT: 'own-shipment-accept',

  // Выдача своих отправлений
  OWN_SHIPMENT_ISSUE: 'own-shipment-issue',

  // Мультиприем своих отправлений
  OWN_SHIPMENT_MULTI_ACCEPT: 'own-shipment-multi-accept',

  // Поиск своих отправлений
  OWN_SHIPMENT_SEARCH: 'own-shipment-search',

  // Управление отчетами географии ПВЗ
  PVZ_YUL_REPORT_MANAGE: 'pvz-yul-report-manage',

  // Управление старыми отчетами
  REPORT_MANAGE: 'report-manage',

  // Управление расписаниями
  SCHEDULE_MANAGE: 'schedule-manage',

  // Блокирование оператов ИМ
  SHOP_USER_BLOCK: 'shop-user-block',

  // Управление операторами ИМ
  SHOP_USER_MANAGE: 'shop-user-manage',

  // Просмотр тарифов
  TARIFF_VIEW: 'tariff-view',

  // Управление заявками на вывод денег
  WITHDRAWAL_MANAGE: 'withdrawal-manage',

  // Отправка тарифов в авито
  SEND_TARIFF_IN_AVITO: 'send-tariff-in-avito',

  // Просмотр отчетов франчайзи своих ПВЗ
  OWN_FRANCH_REPORT_VIEW: 'own-franch-report-view',

  // Просмотр отчетов франчайзи своих ПВЗ
  OWN_STAT_VIEW: 'own-stat-view',

  OWN_USER_MANAGE: 'own-user-manage',

  MULTI_CHANGE_STATUS: 'multi-change-status',

  RECALCULATE_FRANCH_TARIFFS: 'recalculate-franch-tariffs',
  // Выдача любых отправлений
  ANY_SHIPMENT_ISSUE: 'any-shipment-issue',

  // статистика по магаизнам
  SHOP_STAT_VIEW: 'shop-stat-view',

  // Отчет по учету тары
  TARE_REPORT: 'tare-report',

  // Просмотр всех точек в селекторе
  VIEW_ANY_POINTS_IN_SELECTOR: 'view-any-points-in-selector',

  // Просмотр своих точек в селекторе
  VIEW_OWN_POINTS_IN_SELECTOR: 'view-own-points-in-selector',

  VIEW_RATING_PVZ: 'view-rating-pvz',

  ANY_INVENTORY_ADD: 'any-inventory-add',
  OWN_INVENTORY_ADD: 'own-inventory-add',

  ANY_INVENTORY_VIEW: 'any-inventory-view',
  OWN_INVENTORY_VIEW: 'own-inventory-view',

  OWN_INVENTORY_MANAGE: 'own-inventory-manage',

  MANAGE_RATING_POINTS: 'manage-rating-points',
  OWN_RETURNS_MANAGE: 'own-returns-manage',
  ANY_RETURNS_VIEW: 'any-returns-view',
  OWN_RETURNS_VIEW: 'own-returns-view',
  VIEW_FINANCIAL_REPORTS: 'view-financial-reports',
  EDIT_FINANCIAL_REPORTS: 'edit-financial-reports',
}

export default permissions;

