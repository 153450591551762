<template>
  <Grid
    v-if="shipment"
    ref="grid"
    :sortable="false"
    :data-items="goods"
    :columns="columns"
    :selected-field="selectedField"
    :pageable="false"

  >
    <template v-slot:sumprice="{ props }">
      <td>
        {{ props.dataItem.price * props.dataItem.content_quantity }}
      </td>
    </template>
    <template v-slot:cancelHeader="">
      Отказ
    </template>
    <template v-slot:content_name="{props}">
      <td colspan="1">
        <div class="product-name">
          {{ props.dataItem.content_name }}
          <span class="ean" v-if="props.dataItem.ean" @click="showEan(props)">[E]</span>
          <span class="itemmark" v-if="props.dataItem?.good_option?.mark" @click="showMark(props)">[M]</span>
        </div>
        <div class="product-images" v-if="props.dataItem.attached_images && props.dataItem.attached_images.length">
          <el-image
            v-for="(imgObj, index) in props.dataItem.attached_images"
            :key="imgObj.id"
            :src="imgObj.link"
            style="width: 100px; height: 100px; margin-right: 10px; margin-top: 10px;"
            :preview-src-list="getImgLink(props.dataItem.attached_images)"
            :initial-index="index"
            hide-on-click-modal="true"
            fit="cover"
          />
        </div>
      </td>
    </template>
    <template v-slot:cancelCell="{ props }">
      <td colspan="1">
        <input v-if="props.dataItem.selectable" type="checkbox" @click="onSelectionChange(props)"/>
      </td>
    </template>

  </Grid>
  <div>
    <p class="sum">Сумма к оплате: {{ sumPrice }} ₽</p>
    <p v-if="this.shipment"  class="payed">Оплачено: {{ this.shipment.payed_sum }} ₽</p>
  </div>
  <k-dialog v-if="isDialogVisible" :title="dialogTitle" @close="toggleDialog">
    <p :style="{ margin: '25px', textAlign: 'center' }">{{ dialogText }}</p>
  </k-dialog>
</template>

<style scoped>
.itemmark {
  color: green;
  font-weight: bold;
  cursor: pointer;
}

.ean {
  color: rgb(85, 110, 230);
  font-weight: bold;
  cursor: pointer;
}

.sum {
  padding-top: 20px;
}

.payed {
  padding-top: 10px;
}

.product-name {
  display: flex;
  align-items: center;
}

.product-images {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.product-images .el-image {
  margin-right: 10px;
}
</style>

<script>
import {Grid} from '@progress/kendo-vue-grid';
import {Dialog} from '@progress/kendo-vue-dialogs';
import 'element-plus/dist/index.css';
import { ElImage } from 'element-plus';
import '@progress/kendo-theme-default/dist/all.css';

export default {
  name: "ShipmentChooseGoods",
  components: {
    Grid,
    'k-dialog': Dialog,
    'el-image': ElImage,
  },
  props:
    ['shipment']
  ,
  watch: {
    shipment: {
      handler() {
        this.init()
      },
      deep: true
    }
  },
  data() {
    return {
      selectedField: 'cancelled',
      isDialogVisible: false,
      dialogText: '',
      dialogTitle: 'Подробней',
      sumPrice: null,
      goods: [],
      columns: [
        {
          title: "#",
          field: 'index',
          width: "60px"
        },
        {
          cell: 'content_name',
          title: 'Название товара',
        },
        {
          field: 'vendor_code',
          title: 'Артикул',
          width: '120px',
        },
        {
          field: 'content_quantity',
          title: 'Кол-во',
          width: '80px',
        },
        {
          field: 'price',
          title: 'Цена',
          width: '120px',
        },
        {
          cell: 'sumprice',
          title: 'Сумма',
          width: '120px',
        },
        {

          headerCell: 'cancelHeader',
          cell: 'cancelCell',

          title: 'Отказ',
          width: '120px',
        }

      ]

    };
  },
  emits: ['change'],
  methods: {
    init() {
      var rowIndex = 0;
      if (!this.shipment?.has_partial_issuance) {
        this.columns = this.columns.flatMap(item => (item.cell != 'cancelCell') ? [item] : [])
      }
      this.goods = this.shipment.items.map(item => {
        return {...item, cancelled: false, selectable: true, index: ++rowIndex}
      })
      this.goods.push({
        content_name: 'Доставка',
        content_quantity: 1,
        price: this.shipment.delivery_price ?? 0,
        index: ++rowIndex,
        selectable: false
      })
      this.sumPrice = this.shipment.is_np ? this.shipment.price : 0
    },
    toggleDialog() {
      this.isDialogVisible = !this.isDialogVisible
    },
    onSelectionChange(props) {
      props.dataItem[this.selectedField] = !props.dataItem[this.selectedField];
      this.sumPrice = this.calcPrice();
      this.onChange();
    },
    showMark(props) {
      this.dialogTitle = 'Маркировка';
      this.dialogText = props.dataItem?.good_option?.mark ?? '-'
      this.isDialogVisible = true
    },
    showEan(props) {
      this.dialogTitle = 'Штрихкод';
      this.dialogText = props.dataItem.ean
      this.isDialogVisible = true
    },
    calcPrice() {
      if (!this.shipment.is_np) {
        return 0;
      }
      return this.goods.reduce((all, curr) => {
        return all + (curr.cancelled ? 0 : curr.price * curr.content_quantity);
      }, 0);
    },
    onChange() {
      const refusedGoods =  this.goods.flatMap(item => item.cancelled ? [item.uuid] : [])
      this.$emit('change', {
        refusedGoods: refusedGoods,
        sumPrice: this.sumPrice
      });
    },
    getImgLink(ArrayObjects) {
      return ArrayObjects.map(item => item.link);
    },
  },
}
</script>
