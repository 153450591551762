<template>
  <div v-if="loadingItem" class="pt-4">Загрузка..</div>
  <div v-else-if="Object.keys(readItemErrors).length" class="pt-4">
    {{ readItemErrors.error }}
  </div>
  <div v-else>
    <EditDetail
      :shipment="shipment"
      :mode="mode"
      :loading="loadingItem"
      :errors="updateErrors"
      @onEdit="onEdit"
      @onSave="onSave"
      @onDelete="onDelete"
      @onInput="onInput"
    />

    <ModalDelete
      :isModalShow="isDeleteModalShow"
      :isBtnsDisabled="loadingItem"
      title="Отменить ошибочный возврат?"
      @close="toggleDeleteModal(false)"
      @onCancel="toggleDeleteModal(false)"
      @onConfirm="onConfirmDelete"
    />
    <ModalRefuse v-if="isModalRefuseShow" @close="modalRefuseToggle" from="give" :has-parent="shipment.parent_id"/>

    <div class="give-detail__buttons">
      <el-button
          v-if="shipment.status == SHIPMENT_READY_TO_PICK_UP"
          :disabled="isShowAlert"
          @click="$router.push({ name: 'give-confirm-all' , params: { uuid: shipment.uuid } })"
      >
        Выдать
      </el-button>

      <el-button v-if="shipment.status !== SHIPMENT_ISSUED_PARTIALLY" @click="modalRefuseToggle()">Отказ</el-button>
    </div>

    <div class="give-detail__buttons" v-if="canPrint">
      <el-button type="success" @click="() => printSticker(shipment.id)">Этикетка</el-button>
      <el-button type="success" @click="() => receiptAvitoAcceptance(shipment.id)">Квитанция прием</el-button>
      <el-button type="success" @click="() => receiptAvitoIssuance(shipment.id)">Квитанция выдача</el-button>
      <el-button v-if="shipment.point_src?.need_declaration" type="success" @click="() => printDocumentsForСustoms()">Документы таможня</el-button>
    </div>
    <div class="give-detail__buttons" v-if="canSendToGb || canProlong">
      <el-button type="danger" v-if="canSendToGb" @click.prevent="sendToGb(shipment.id)">Переотправить в GB</el-button>
      <el-button type="danger" v-if="canProlong" @click.prevent="prolong()">Продлить</el-button>
    </div>

    <ShipmentGoods :shipment="shipment"/>

    <el-tabs v-model="activeName" class="shipments-detail-tabs" @tab-click="tabClick">
      <el-tab-pane label="История" name="shipment-history">
        <ShipmentHistory :shipment="shipment"/>
      </el-tab-pane>
      <el-tab-pane label="Логи" name="shipment-logs" >
        <ShipmentLogTable :logs="logs" v-loading="loadingLogs" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import EditDetail from "src/components/shipments/EditDetail.vue";
import ModalDelete from "src/components/modal/ModalDelete.vue";
import ShipmentGoods from "src/components/shipments/ShipmentGoods.vue";
import ShipmentHistory from "src/components/shipments/ShipmentHistory.vue";
import {usePrint} from '@/composition/usePrint.js';
import {ElNotification} from "element-plus";
import {
  SHIPMENT_CANCELLED,
  SHIPMENT_ISSUED_PARTIALLY,
  SHIPMENT_READY_TO_PICK_UP
} from "@/constants";
import {ref} from "vue";
import {mapActions} from "vuex";
import ShipmentLogTable from "@/components/shipments/ShipmentLogTable.vue";
import {downloadFile, getFileNameWithExtensionFromHeaders} from "@/store/helper";
import ModalRefuse from "@/components/modal/ModalRefuse";

export default {
  name: "AdminShipmentsDetail",
  components: {ShipmentLogTable, EditDetail, ModalDelete, ShipmentGoods, ShipmentHistory, ModalRefuse,},
  setup() {
    return {
      ...usePrint(),
    }
  },
  data() {
    return {
      SHIPMENT_READY_TO_PICK_UP: SHIPMENT_READY_TO_PICK_UP,
      SHIPMENT_ISSUED_PARTIALLY: SHIPMENT_ISSUED_PARTIALLY,
      loadingItem: true,
      loadingLogs: false,
      isModalRefuseShow: false,
      mode: "read", //'edit'
      isDeleteModalShow: false,
      activeName: ref('shipment-history'),
      logs: [],
      updateErrors: {},
    };
  },
  mounted() {
    let dicts = [];
    if (!this.$store.state.points.list.length) {
      dicts.push(this.$store.dispatch("points/getList"));
    }
    if (!this.$store.state.ceils.list.length) {
      dicts.push(this.$store.dispatch("ceils/getList"));
    }
    Promise.all(dicts).then(async () => {
      await this.getItem();
      this.$store.commit("SET_TITLE", "Отправление " + this.shipment.number);
    });
    this.logs = [];
  },
  computed: {
    canSendToGb() {
      return this.shipment.status != SHIPMENT_CANCELLED;
    },
    canProlong() {
      return this.shipment.status == SHIPMENT_READY_TO_PICK_UP;
    },
    canPrint() {
      return +this.shipment.status && +this.shipment.status != SHIPMENT_CANCELLED;
    },
    shipment() {
      return this.$store.state.shipments.readItem;
    },
    readItemErrors() {
      return this.$store.state.shipments.errors.readItem;
    },
  },
  methods: {
    printDocumentsForСustoms() {
      this.$store.dispatch('apiV2DocumentStore/downloadKaliningradInvoice', this.shipment.uuid).then(response => {
        const fileNameWithExtension = getFileNameWithExtensionFromHeaders(response.headers);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        downloadFile(url, fileNameWithExtension)
      });

      this.$store.dispatch('apiV2DocumentStore/downloadKaliningradContract', this.shipment.uuid).then(response => {
        const fileNameWithExtension = getFileNameWithExtensionFromHeaders(response.headers);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        downloadFile(url, fileNameWithExtension)
      });
    },
    async getItem() {
      this.loadingItem = true;
      await this.$store.dispatch("shipments/readItem", this.$route.params.id);
      this.loadingItem = false;
    },
    ...mapActions({
      fetchLogs: 'apiV2ShipmentLogStore/fetch',
    }),

    prolong() {
      this.$store.dispatch('apiV2ShipmentStore/prolong', this.shipment.uuid).then((data) => {
        ElNotification({
          title: 'Успешно продлено',
          message: data.message,
          type: 'success',
        });
      }, (error) => {
        return ElNotification({
          title: 'Ошибка',
          message: error,
          type: 'error',
        });
      });
    },
    sendToGb(shipmentId) {
      this.$store.dispatch('apiV2ShipmentStore/sendToGb', {shipment_id: shipmentId}).then(() => {
        return ElNotification({
          title: 'Успешно отправлено',
          message: 'Успешно отправлено',
          type: 'success',
        });

      }, (error) => {
        return ElNotification({
          title: 'Ошибка',
          message: error,
          type: 'error',
        });
      });

    },
    modalRefuseToggle() {
      this.isModalRefuseShow = !this.isModalRefuseShow;
    },
    onEdit() {
      this.mode = "edit";
    },
    async onSave() {
      this.loadingItem = true;
      await this.$store.dispatch("shipments/updateItem").then(() => {
        this.updateErrors = {};
        this.mode = "read";
        this.getItem();
      }, (error) => {
        this.updateErrors = error.response.data.errors;
      }).finally(() => {
        this.loadingItem = false;
      });
    },
    onDelete() {
      this.isDeleteModalShow = true;
    },
    onInput(valObj) {
      let data = {...this.shipment, ...valObj};
      this.$store.commit("shipments/SET_READ_ITEM", data);
    },
    toggleDeleteModal(val) {
      this.isDeleteModalShow = val;
    },
    async onConfirmDelete() {
      this.loadingItem = true;
      await this.$store.dispatch("shipments/delete", this.shipment.id);
      this.loadingItem = false;
      this.mode = "read";
      await this.getItem();
    },
    tabClick(tab) {
      if (tab.props.name !== 'shipment-logs' || this.logs.length > 0) {
        return;
      }

      this.loadingLogs = true;
      this.fetchLogs(this.$route.params.id).then((result) => {
        this.logs = result;
      }).finally(() => {
        this.loadingLogs = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.shipments-detail-tabs {
  margin-top: 32px;
}
</style>
