<template>
  <div v-if="isLoading" class="pt-3 pb-3">Загрузка...</div>

  <div v-if="!isLoading" style="padding-top:10px">
    <div class="alert alert-secondary"
         style="font-variant-ligatures: no-contextual;padding-top: 10px" role="alert"
         v-if="freight && freight.shipments">
      <span>
        Перевозка ID: {{ freight.id }}
        | №: {{ freight.number }}
        | Принято: {{ acceptedShipments.length }} из {{ freight.shipments.length }}
        | Статус: {{ freightStatusName }}
      </span>
    </div>
    <div class="storageCreate-context-container" v-if="this.freight">
      <div class="send-PVZ storageCreate-items">
        <h2 class="title-style-main">
          Отправления в перевозке
        </h2>
        <div class="container-search">
          <form class="sort-items" @submit.prevent="scanShipment"
          >
            <span class="custom-search">
              <button class="invise-searchButton"/>
              <input
                v-model="barcode"
                :disabled="!isValidFreightStatusForAccept"
                required

                class="custom-search-input form-control"
                placeholder="Сканировать"
                type="search"
              >
              <button
                @click="clearBarcode()"
                type="button"
                class="close-icon"
              />
            </span>
          </form>
        </div>
        <create-shipment-table
          :items="this.shipmentsToAccept"
          :show-checkbox="false"
          :show-alert="false"


        />

      </div>
      <div class="ready-to-send  storageCreate-items" style="width:700px">
        <h2 class="title-style-main">
          Принятые отправления
        </h2>
        <div class="container-search storageCreate-custom-margin" style="height:40px">
        </div>
        <create-shipment-table
          :items="this.acceptedShipments"
          :show-status="true"
          :show-cell="true"
          :show-alert="true"


        />
        <div class="storage-buttons">
          <button
            :disabled="!isValidFreightStatusForAccept"
            class="mainButton"
            @click.prevent="onButtonfinishFreightReceptionClick"
          >
            Завершить приемку
          </button>
        </div>
      </div>
    </div>


    <cell-selector
      v-model="isPlacementVisible"
      :shipment-id="scanedShipmentId"
      :freight-id="freight.id"
      :initial-place="initialPlace"
      @place="onPlacementDialogClosed"
    />
  </div>
</template>
<style scoped>
.table-items-transport {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
</style>
<script>

import {mapActions, mapGetters, mapState} from "vuex";
import {useCeils} from '@/composition/useCeils.js';
import {
  FREIGHT_STATUS_ACCEPTED_ON_POINT,
  FREIGHT_STATUS_VALUES,

} from "@/constants";
import {ElMessageBox, ElNotification} from "element-plus";
import CreateShipmentTable from "@/components/warehouse/CreateShipmentTable";
import CellSelector from "@/components/freights/franchisee/CellSelector";
import {useBarcodeScan} from "@/composition/useBarcodeScan";
import {useSound} from "@/composition/useSound";


export default {
  name: 'FreightReceptionWithBarcodes',

  components: {CreateShipmentTable, CellSelector},

  setup() {


    return {
      ...useCeils(),
      ...useBarcodeScan(),
      ...useSound()
    }
  },

  data: () => ({
    isLoading: false,
    isPlacementVisible: false,
    initialPlace: null,
    scanedShipmentId: null,
    barcode: null,
    shipmentsToAccept: [],
    acceptedShipments: [],

  }),

  computed: {
    ...mapState({
      freight: state => state.freights.editItem,
    }),

    ...mapGetters({
      shipmentsStatusNameById: 'shipments/statusNameById',
    }),


    isValidFreightStatusForAccept() {
      if (!this.freight) {
        return false;
      }

     // if (!this.freight.shipments || !this.freight.shipments.length) {
     //   return false;
     // }

      return (parseInt(this.freight.status) === FREIGHT_STATUS_ACCEPTED_ON_POINT)
    },

    freightStatusName() {
      if (!this.freight) {
        return null;
      }
      return FREIGHT_STATUS_VALUES[parseInt(this.freight.status)];
    }
  },

  watch: {
    isReceivingVisible(value) {
      if (!value) {
        this.initData()
      }
    }
  },
  mounted() {
    this.isLoading = true
    this.initData()
    window.addEventListener('barcodescan', this.fromScanner);
  },
  unmounted() {
    window.removeEventListener('barcodescan', this.fromScanner);
  },

  methods: {
    ...mapActions({
      readItem: 'freights/readItem',
      finished: 'freights/finished',
    }),
    clearBarcode() {
      this.barcode = ''
    },
    playSound(success) {
      if (success) {
        this.playPositiveSound();
      } else {
        this.playNegativeSound()
      }
    },
    async fromScanner(e) {
      let scanedBarcode = e.detail
      if (this.isPlacementVisible) {
        this.initialPlace = this.parseEan(scanedBarcode)
      } else {
        this.barcode = scanedBarcode
        this.scanShipment()
      }
    },

    async initData() {
      await this.readItem(this.$route.params.id);
      if (!this.freight || !this.freight.shipments) {
        return [];
      }
      this.acceptedShipments = [].concat(this.freight.incorrect_shipments).concat(this.freight.ready_shipments);
      this.shipmentsToAccept = this.freight.shipments_on_way;
      this.isLoading = false

    },

    onPlacementDialogClosed(shipment) {
      // Сбрасываем ячейку
      this.initialPlace = null;

      if (!shipment) {
        this.barcode = '';
        this.playNegativeSound()
        return;

      }
      this.playPositiveSound()
      this.removeFromList(this.shipmentsToAccept, shipment)

      this.addToList(this.acceptedShipments, shipment)
      this.barcode = '';

    },
    removeFromList(list, shipment) {
      const index = list.findIndex(item => item.id === shipment.id)
      if (index >= 0) {
        list.splice(index, 1)
      }
    },
    addToList(list, shipment) {
      const index = list.findIndex(item => item.id === shipment.id)

      if (index >= 0) {
        return;
      }
      list.unshift(shipment)
    },
    showPlacementDialog(result) {
      this.scanedShipmentId = result.shipment.id;
      this.isPlacementVisible = true;
    },

    async scanShipment() {
      this.$store.dispatch('apiV2ShipmentStore/scanBarcode', {barcode: this.barcode}).then(result => {
        this.playSound(true)
        this.showPlacementDialog(result)
      }, () => {
        this.barcode = ''
        this.playSound(false)
      });
    },

    onError(alert) {

      return ElNotification({
        title: 'Ошибка',
        message: alert,
        type: 'error',
      });
    },


    async onButtonfinishFreightReceptionClick() {
      if (this.shipmentsToAccept.length > 0) {
        await ElMessageBox.confirm(
          'В Перевозке имеются неотсканированные отправления. ' +
          'Вы действительно хотите завершить Перевозку с недостачей?',
          'Подтверждение действия',
          {
            confirmButtonText: 'Завершить',
            cancelButtonText: 'Отменить',
            cancelButtonClass: 'el-button--info'
          },
        )
      }
      await this.finishFreightReception();
      await this.initData();
    },

    async finishFreightReception() {
      try {
        const body = {
          id: this.freight.id,
          data: {is_new: 1}
        };
        await this.finished(body);
      } catch ({message}) {
        ElNotification({
          title: 'Ошибка',
          message: message,
          type: 'error',
        });
      }
    },
  }
};
</script>
