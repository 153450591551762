import CodReport from "./CodReport.vue";
import permissions from "@/modules/auth/auth.types.ts";

export const codReportRoutes = [
  {
    path: "/cod-report",
    component: CodReport,
    name: "cod-report",
    meta: {
      title: "Отчет по РКО ежедневный",
      middleware: [permissions.VIEW_FINANCIAL_REPORTS, permissions.EDIT_FINANCIAL_REPORTS]
    },
  },
];
