<template v-if="this.getUserLinks">
  <div class="sideBar__logo">
    <router-link to="/"
    ><img src="../../assets/img/logo_icon.svg" alt=""
    /></router-link>
  </div>
  <div class="sideBar__components">
    <ul class="sideBar__list">
      <NavigationGroup
        v-for="(item, key) in this.getUserLinks"
        v-bind:key="key"
        :item="item"
      />
    </ul>
  </div>
  <div style="margin-top: 30px">
    <a class="sideBar__exit-btn" @click.prevent="exit" href="#exit">Выход</a>
  </div>
</template>

<script>
import NavigationGroup from "./NavigationGroup.vue";
import permissions from "@/modules/auth/auth.types.ts";
import store from "@/store";

export default {
  name: "Sidebar",
  components: {
    NavigationGroup,
  },
  props: {
    user: Object
  },
  setup(props) {
    const points = store.getters['user/currentUser'].points;

    const inPermission = (name) => {
      if (name === null) {
        return true;
      }

      for (const user_permission of props.user.permissions) {
        if (name === user_permission) {
          return true;
        }
      }

      return false;
    }

    const links = [
      {
        title: "ПВЗ",
        icon: "adresses",
        links: points.map(item => {
          return {
            title: `${item.street}, ${item.house}`,
            id: item.id,
            link: `/address/${item.id}`,
            permission: inPermission(null)
          };
        })
      },
      {
        title: "Отправка на склад",
        icon: "warehouse",
        links: [
          {
            title: "Отправка на склад",
            link: "/warehouse",
            permission: inPermission(permissions.OWN_SENDING_VIEW)
          }
        ]
      },
      {
        title: 'Пользователи',
        icon: "user",
        links: [
          {
            title: "Пользователи",
            icon: "user",
            link: "/users",
            permission: inPermission(permissions.ANY_USER_MANAGE)
          },
          {
            title: 'Заявки на регистрацию',
            link: '/users/requestsRegistration',
            permission: inPermission(permissions.ANY_USER_REQUEST_MANAGE)
          }
        ],
      },
      {
        title: "Перевозки",
        icon: "transport",
        links: [
          {
            title: "Перевозки",
            icon: "transport",
            link: "/freights",
            permission: inPermission(permissions.OWN_FREIGHT_VIEW)
          },
        ]
      },
      {
        title: "Акты ПП",
        icon: "send",
        links: [
          {
            title: "Акты приёма-передачи",
            link: "/pp-acts",
            permission: inPermission(permissions.OWN_ACT_VIEW)
          }
        ]
      },
      {
        title: "Мультиприем",
        icon: "send",
        links: [
          {
            title: "Мультиприем",
            link: "/multi-accept",
            permission: inPermission(permissions.OWN_SHIPMENT_MULTI_ACCEPT)
          }
        ]
      },
      {
        title: "Отправления",
        icon: "profile",
        links: [
          {
            title: "Установка статусов",
            link: "/shipments/set-status-for-shipments",
            permission: inPermission(permissions.MULTI_CHANGE_STATUS)
          },
          {
            title: "Пересчитать тарифы",
            link: "/shipments/recalculate-shipments",
            permission: inPermission(permissions.RECALCULATE_FRANCH_TARIFFS)
          },
          {
            title: "Поиск отправлений",
            icon: "profile",
            link: "/shipments",
            permission: inPermission(permissions.OWN_SHIPMENT_SEARCH) || inPermission(permissions.ANY_SHIPMENT_SEARCH)
          }
        ]
      },
      {
        title: "Прием отправлений",
        icon: "send",
        links: [
          {
            title: "Прием отправлений",
            link: "/accept",
            permission: inPermission(permissions.OWN_SHIPMENT_ACCEPT)
          },
        ]
      },
      {
        title: "Выдача отправлений",
        icon: "send",
        links: [
          {
            title: "Выдача отправлений",
            link: "/give",
            permission: inPermission(permissions.OWN_SHIPMENT_ISSUE)
          },
        ]
      },
      //  Пустой компонент
      // {
      //   title: "Тарифы",
      //   icon: "money",
      //   links: [
      //     {
      //       title: "Тарифы",
      //       link: "/tariffs",
      //       permission: inPermission('any-user-request-manage')
      //     }
      //   ]
      // },
      // Убран  по просьбе Екатерины
      // {
      //   title: "Данные физлиц",
      //   icon: "back",
      //   links: [
      //     /** Непонятно какое право */
      //     {
      //       title: "Данные физлиц",
      //       link: "/clients",
      //       permission: inPermission('any-user-request-manage')
      //     }
      //   ]
      // },
      //  Пустой компонент
      // {
      //   title: "Справочник ПВЗ",
      //   icon: "adresses",
      //   links: [
      //     {
      //       title: "Справочник ПВЗ",
      //       link: "/points",
      //       permission: inPermission('any-user-request-manage')
      //     }
      //   ]
      // },
      //  Пустой компонент
      // {
      //   title: "Контрагенты",
      //   icon: "users",
      //   links: [
      //     {
      //       title: "Контрагенты",
      //       link: "/partners",
      //       permission: inPermission('any-user-request-manage')
      //     }
      //   ]
      // },
      {
        title: "Отчеты",
        icon: "report",
        links: [
          /** Непонятно какое право */
          {
            title: "Отчет по ИМ",
            icon: "report",
            link: "/report",
            permission: inPermission(permissions.OWN_FRANCH_REPORT_VIEW)
          },
          {
            title: "Отчет остатки",
            link: "/report/remains",
            permission: inPermission(permissions.OWN_REPORT_SURPLUS)
          },
          {
            title: "Отчет утилизация",
            link: "/report/destroyed",
            permission: inPermission(permissions.OWN_REPORT_UTIL)
          },
          {
            title: "Отчет по таре",
            link: "/report/use-tare",
            permission: inPermission(permissions.TARE_REPORT)
          },
        ]
      },
      {
        title: "Helpdesk",
        icon: "help",
        links: [
          {
            title: "Helpdesk",
            link: "/helpdesk",
            permission: inPermission(permissions.OWN_HELPDESK)
          },
          //  Пустой компонент
          // {
          //   title: "Помощь",
          //   link: "/help",
          //   permission: inPermission('any-user-request-manage')
          // }
        ]
      },
      {
        title: "Статистика",
        icon: "statistic",
        links: [
          /** Непонятно какое право */
          {
            title: "Статистика по ПВЗ",
            link: "/statistic",
            permission: inPermission(permissions.OWN_STAT_VIEW)
          },
          {
            title: "Статистика по магазинам",
            link: "/statistic/shop",
            permission: inPermission(permissions.SHOP_STAT_VIEW)
          },

        ]
      },
      {
        title: "Отчет по РКО",
        icon: "report",
        links: [
          {
            title: "Отчет по РКО",
            link: "/cod-report",
            permission: inPermission(permissions.VIEW_FINANCIAL_REPORTS) && inPermission(permissions.EDIT_FINANCIAL_REPORTS)
          },
        ]
      },
      //  Заполнен только тестовыми данными, смысла нету
      // {
      //   title: "Инструкции",
      //   icon: "guide",
      //   links: [
      //     {
      //       title: "Инструкции",
      //       link: "/instructions",
      //       permission: inPermission('any-user-request-manage')
      //     }
      //   ]
      // },
      //  Пустой компонент
      // {
      //   title: "Настройки системы",
      //   icon: "wrench",
      //   links: [
      //     {
      //       title: "Настройки системы",
      //       link: "/settings",
      //       permission: inPermission('any-user-request-manage')
      //     }
      //   ]
      // },
      //  Нету никакой логики в компоненте
      // {
      //   title: "Прием возвратов",
      //   icon: "back",
      //   links: [
      //     {
      //       title: "Прием возвратов",
      //       link: "/back",
      //       permission: inPermission('any-user-request-manage')
      //     }
      //   ]
      // },
      {
        title: "Авито",
        icon: "money",
        links: [
          {
            title: "Тарифы в Авито",
            link: "/avito",
            permission: inPermission(permissions.SEND_TARIFF_IN_AVITO)
          }
        ]
      },
      //  Пустой компонент
      // {
      //   title: "Инвентаризация",
      //   icon: "inventory",
      //   links: [
      //     {
      //       title: "Инвентаризация",
      //       link: "/inventory",
      //       permission: inPermission('any-user-request-manage')
      //     }
      //   ]
      // },
      //  Нету никакой логики в компоненте
      // {
      //   title: "Адресное хранение",
      //   icon: "cell",
      //   links: [
      //     {
      //       title: "Адресное хранение",
      //       link: "/folder",
      //       permission: inPermission('any-user-request-manage')
      //     }
      //   ]
      // },
      {
        title: "Инвентаризация",
        icon: "money",
        links: [
          {
            title: "Инвентаризации",
            link: "/inventory",
            permission: inPermission(permissions.ANY_INVENTORY_VIEW) || inPermission(permissions.OWN_INVENTORY_VIEW)
          },
          {
            title: "Проведение",
            link: "/inventory/manage",
            permission: inPermission(permissions.OWN_INVENTORY_MANAGE)
          },
        ]
      },
      {
        title: "Рейтинг",
        icon: "money",
        links: [
          {
            title: "Рейтинг ПВЗ",
            link: "/point-rating",
            permission: inPermission(permissions.MANAGE_RATING_POINTS)
          },
        ]
      },
      {
        title: "Возвраты",
        icon: "guide",
        links: [
          {
            title: "Возвраты",
            link: "/return-acts",
            permission: inPermission(permissions.OWN_RETURNS_VIEW) || inPermission(permissions.ANY_RETURNS_VIEW)
          },
          {
            title: "Создать",
            link: "/return-act-create",
            permission: inPermission(permissions.OWN_RETURNS_VIEW)
          },
          {
            title: "Передача возврата",
            link: "/issue-return",
            permission: inPermission(permissions.OWN_RETURNS_VIEW)
          },
        ]
      },

      {
        title: "Новости",
        icon: "news",
        links: [
          /** Непонятно какое право */
          {
            title: "Новости",
            link: "https://t.me/+0EuqpdfGnvY0Njli",
            permission: inPermission(null),
            target: '_blank'
          },
          //  Пустой компонент
          // {
          //   title: "Помощь",
          //   link: "/help",
          //   permission: inPermission('any-user-request-manage')
          // }
        ]
      },
      {
        title: "Учебный портал",
        icon: "education",
        links: [
          {
            title: "Учебный портал",
            link: "https://exmail.getcourse.ru/",
            permission: inPermission(null),
            target: '_blank'
          },
        ]
      },
      {
        title: "Регламенты работы ПВЗ",
        icon: "education",
        links: [
          {
            title: "Регламенты работы ПВЗ",
            link: "https://new-acc-space-10669.ispring.ru/app/preview/f42fa38e-dbf7-11ec-96e2-f2bb28b1c2df",
            permission: inPermission(null),
            target: '_blank'
          },
        ]
      },
      {
        title: "Регламенты работы ПВЗ",
        icon: "education",
        links: [
          {
            title: "Рейтинг ПВЗ",
            link: "https://lookerstudio.google.com/u/0/reporting/4fcc35c4-8276-4bd4-9264-e72233c40f88/page/MSQqD",
            permission: inPermission(permissions.VIEW_RATING_PVZ),
            target: '_blank'
          },
        ]
      },
      {
        title: "Заявки",
        icon: 'registration',
        links: [
          /** Непонятно какое право */
          {
            title: "Создать заявку",
            link: "/registration/legal-entity-edit",
            permission: inPermission(permissions.CREATE_REQUEST_REGISTRATION),
          },
        ]
      },
      {
        title: "Уведомления",
        icon: 'news',
        links: [
          {
            title: "Уведомления",
            link: "/notifications",
            permission: inPermission(null),
          },
        ]
      },
    ];

    return {links};
  },
  computed: {
    getUserLinks() {
      return this.links;
    }
  },
  methods: {
    exit() {
      this.$store.dispatch("user/logout");
    },
  },
};
</script>

<style lang="scss">
.sideBar__exit-btn {
  display: block;
  background: #556ee6;
  padding: 16px 30px;
  position: absolute;
  bottom: 13px;
  width: 100%;
  color: #ffffff;
  text-decoration: none;

  &:hover,
  &:visited {
    color: #ffffff;
  }
}
</style>
